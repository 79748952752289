.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #products,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}

.bot {
  bottom: 5;
  right: 5;
  position: fixed;
  width: 350px;
}

.btn {
  font-size: 20px;
  float: right;
  width: 100%;
  padding: 8px;
  background-color: #5c81ed;
}

.rsc-header-title {
  color: white;
}

.App {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.root-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}




.terms-container {
  padding: 60px 50px 50px 50px;
}

.terms-container p,
.terms-container li {
  font-weight: 500;
  font-size: 18px;
}

.terms-container h1 {
  font-size: 50px;
  font-weight: bold;
  margin-top: 5px;
}

.terms-container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
}

/* Responsive adjustments for medium and smaller screens */
@media (max-width: 768px) {
  .terms-container p,
  .terms-container li {
    font-size: 16px;
  }

  .terms-container h1 {
    font-size: 40px;
  }

  .terms-container h2 {
    font-size: 20px;
  }
}

/* Further adjustments for smaller screens like mobile */
@media (max-width: 480px) {
  .terms-container p,
  .terms-container li {
    font-size: 14px;
  }

  .terms-container h1 {
    font-size: 32px;
  }

  .terms-container h2 {
    font-size: 18px;
  }
}

.termsandconditions_description{
  box-sizing:content-box;
  font-weight: 650;
  color: black;
  font-size: 20px;
}

@media (max-width: 768px) {
  .termsandconditions_description{
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  .termsandconditions_description{
    font-size: 12px;
  }
}
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.footer-container a {
  text-decoration: none;
  color: inherit;
  margin-left: 10px;
}

.footer-container p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.footer-container a:hover {
  text-decoration: none;
}

.back-button {
  font-size: 18px;
  font-weight: 500;
  color: white; 
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-bottom: 20px;
}

/* .back-button:hover {
  text-decoration: none;
  color: #0056b3; 
} */

.company-intro{

}

@media (min-width: 900px) and (max-width: 991px) {
  .company-intro{
    width: 50vw;
  }
}